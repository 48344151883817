import store from '../store'

const snackbarPlugin = {
  showMessage: ({ content = '', color = '', timeout = '' }) => {
    store.commit('snackbar/showMessage', { content, color, timeout }, { root: true })
  },
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error('Please provide vuex store.')
    }
    Vue.prototype.$snackbar = snackbarPlugin
  },
}

export default snackbarPlugin
