import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'
import { exportData } from '@/utils'
import { mdiDomain } from '@mdi/js'
import { ref, watch } from 'vue'

export default function useCompaniesList() {
  const companiesListTable = ref([])
  const tableColumns = [
    { text: '', value: 'logo' },
    { text: 'RUT', value: 'rut' },
    { text: 'Razon Social', value: 'name' },
    { text: 'Fantasia', value: 'fantasyName' },
    { text: 'Contacto', value: 'defaultContact' },
    {
      text: 'Acciones',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const totalCompanyListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const totalLocal = ref([])
  const selectedRows = ref([])
  const timer = ref('')

  // fetch data
  const fetchCompanies = () => {
    store
      .dispatch('app-company/fetchCompanies', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(response => {
        const { filteredData, total, companyTotal } = response.data

        companiesListTable.value = filteredData
        totalCompanyListTable.value = total
        totalLocal.value = companyTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  // fetch data
  const deleteCompany = id => {
    store
      .dispatch('app-company/deleteCompany', { id })
      .then(response => {
        loading.value = true
        selectedRows.value = []
        fetchCompanies()
        snackbarPlugin.showMessage({ content: 'Eliminado Correctamente', color: 'success', timeout: 10000 })
      })
      .catch(e => {
        snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
      })
  }

  const exportCompanies = async () => {
    let data = companiesListTable.value.map(e => ({
      RUT: e.rut,
      'Razon Social': e.name,
      Fantasia: e.fantasyName,
      Direccion: e.address,
      Telefono: e.phone,
      Sector: e.sector,
      Contacto: e.contactData.length ? `${e.contactData[0].name} ${e.contactData[0].lastname}` : '',
      'Tel Contacto': e.contactData.length && e.contactData[0].phone ? `${e.contactData[0].phone}` : '',
      'Email Contacto': e.contactData.length && e.contactData[0].email ? `${e.contactData[0].email}` : '',
    }))

    exportData(data, 'empresas.csv')
  }

  watch([searchQuery, options], () => {
    if (searchQuery) {
      if (timer.value) {
        clearTimeout(timer.value)
        timer.value = null
      }
      timer.value = setTimeout(() => {
        loading.value = true
        selectedRows.value = []
        fetchCompanies()
      }, 800)
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTotalIcon = total => {
    if (total === 'Total Regulares') return { icon: mdiDomain, color: 'primary' }
    return { icon: mdiDomain, color: 'primary' }
  }

  return {
    exportCompanies,
    companiesListTable,
    tableColumns,
    searchQuery,
    totalCompanyListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    timer,
    fetchCompanies,
    deleteCompany,
    resolveTotalIcon,
  }
}
