import store from '@/store'
import useCompaniesList from '@/views/apps/company/company-list/useCompaniesList'
import companyStoreModule from '@/views/apps/company/companyStoreModule'
import { onUnmounted } from 'vue'

export default function companiesList() {
  const appCompany = 'app-company'

  // Register module
  if (!store.hasModule(appCompany)) store.registerModule(appCompany, companyStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(appCompany)) store.unregisterModule(appCompany)
  })

  const { companiesListTable, fetchCompanies } = useCompaniesList()

  return {
    fetchCompanies,
    companiesListTable,
  }
}
