<template>
  <v-autocomplete
    label="Empresa"
    v-model="selected"
    :items="companiesListTable"
    item-text="name"
    item-value="_id"
    no-data-text="No hay resultados"
    :search-input.sync="searchInput"
    :disabled="disabled"
    :rules="$props.rules"
    outlined
    dense
    @change="onSelection"
    clearable
    :filter="
      (item, queryText, itemText) => {
        return (
          itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.rut.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.fantasyName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        )
      }
    "
  >
    <template #item="data">
      <template>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.rut }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
import companiesList from '@/views/apps/company/company-list/companiesList'
import { ref, watch } from 'vue'
import ability from '@/plugins/acl/ability'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    rules: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const selected = ref(props.value)
    const { companiesListTable, fetchCompanies } = companiesList()
    const searchInput = ref('')

    if (ability.can('update', 'profile', 'company')) fetchCompanies()

    watch(
      () => props.value,
      value => {
        selected.value = value
      },
    )

    const onSelection = () => {
      searchInput.value = ''
      emit('input', selected.value)
      emit(
        'change',
        companiesListTable.value.find(c => c._id == selected.value),
      )
    }

    return {
      searchInput,
      onSelection,
      selected,
      companiesListTable,
    }
  },
}
</script>
